import { nanoid } from "nanoid";

// HEAD DATA
export const headData = {
  title: "", // e.g: 'Name | Developer'
  lang: "", // e.g: en, es, fr, jp
  description: "", // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: "",
  name: "",
  subtitle: "",
  cta: "",
};

// ABOUT DATA
export const aboutData = {
  img: "profile.jpg",
  paragraphOne: "",
  paragraphTwo: "",
  paragraphThree: "",
  resume: "", // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: "project.jpg",
    title: "",
    info: "",
    info2: "",
    url: "",
    repo: "", // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: "project.jpg",
    title: "",
    info: "",
    info2: "",
    url: "",
    repo: "", // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: "project.jpg",
    title: "",
    info: "",
    info2: "",
    url: "",
    repo: "", // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: "",
  btn: "",
  email: "jhonflores@konter.com.mx",
  //contacto@konter.com.mx
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: "twitter",
      url: "#",
    },
    {
      id: nanoid(),
      name: "facebook",
      url: "https://www.facebook.com/kontermx/",
    },
    {
      id: nanoid(),
      name: "instagram",
      url: "https://www.instagram.com/kontermx/?hl=es-la",
    },
    {
      id: nanoid(),
      name: "whatsapp",
      url: "https://wa.me/9994425592",
    },
  ],
};
